import React, { useContext, useReducer, Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import { useLocation } from 'react-use'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'

import Loader from './components/_Partials/Loader'
import Context from './context'
import Reducer from './reducer'
import { initGoogleAnalytics } from './utiltiies/analytics'

const HomePage = lazy(() => import('./pages/Home'))
const AboutPage = lazy(() => import('./pages/About'))
const ContactPage = lazy(() => import('./pages/Contact'))


const Root = () => {
    const location = useLocation()
    const initialState = useContext(Context)
    const [state, dispatch] = useReducer(Reducer, initialState)

    initGoogleAnalytics(location.host)


    return (
        <Router>
            <Context.Provider value={{ state, dispatch }} >
                <Suspense fallback={Loader}>
                    <Switch>
                        <Route exact path='/' component={HomePage} />
                        <Route exact path='/about' component={AboutPage} />
                        <Route exact path='/contact' component={ContactPage} />
                        <Route path='/*' component={HomePage} />
                    </Switch>
                </Suspense>
            </Context.Provider>
        </Router>
    )
}

ReactDOM.render(<Root />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
