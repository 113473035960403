import React from 'react'
import { Grid, CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'


const Loader = ({ classes }) => {
    return (
        <Grid container direction='column' className={classes.loader}
            justify='center' alignItems='center'
        >
            <CircularProgress />
        </Grid>
    )
}

const styles = {
    loader: {
        height: '100vh',
    },
}

export default withStyles(styles)(Loader)
